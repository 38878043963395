<template>
    <sort-list v-model="list" :defaultQuery="defaultQuery" @init="init" ref="list" url="/expert/all" :filters="filters">
        <template v-for="item in list">
            <manage-gride :key="item.id" :info="item"></manage-gride>
        </template>
    </sort-list>
</template>

<script>
import { typeOptions, intePropLawStatus } from '../../utils/variables';
import SortList from '../../components/page/SortList.vue';
import ManageGride from '../../components/list/ExpertGride.vue';
export default {
    components: { SortList, ManageGride },
    created() {
        this.init();
    },
    data() {
        return {
            fieldOptions: [],
            list: [],
            defaultQuery: {
                isExpert: true
            },
            typeOptions,
            intePropLawStatus,
            affiliationOptions: []
        };
    },
    computed: {
        fieldOptionsIndex() {
            return [...this.fieldOptions].filter((item, index) => {
                return index < 20;
            });
        },
        filters() {
            return [
                {
                    name: '专业领域',
                    key: 'field',
                    type: 'select',
                    list: [...this.fieldOptionsIndex]
                },
                {
                    name: '研究方向',
                    key: 'affiliation',
                    type: 'select',
                    list: [...this.affiliationOptions]
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 15 })
                .then(res => {
                    this.affiliationOptions = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                    return this.$http.post('/setting/byFlag', { flag: 1 });
                })
                .then(res => {
                    if (res.length > 0) {
                        this.fieldOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });

                        this.$nextTick(() => {
                            this.$refs.list.init();
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped></style>
